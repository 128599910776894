<div
  class="step content_step step-active in"
  data-step="11"
  data-stepname="ConfirmarDatos"
  data-stephash="ConfirmarDatos"
  data-transactionstep="11"
  data-percent="90"
>
  <h1 class="section-step__title animate" style="transition-delay: 0.15s">
    Para terminar, confirmá los datos ingresados.
  </h1>

  <div class="row">
    <div class="col-12 position-static">
      <div
        class="section-step__subtitle animate"
        style="transition-delay: 0.25s"
      >
        Datos personales
      </div>

      <div class="data-confirm animate" style="transition-delay: 0.3s">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Tarjeta</div>
            <div class="data-confirm__value">{{ cardData }}<span *ngIf="hireFinancialProtection"> con seguro</span></div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Nombre completo</div>
            <div class="data-confirm__value">{{ name_lastName }}</div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Número de celular</div>
            <div class="data-confirm__value">{{ phone }}</div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Número de documento</div>
            <div class="data-confirm__value">{{ dni }}</div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Email</div>
            <div class="data-confirm__value">{{ email }}</div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Estado civil</div>
            <div class="data-confirm__value">{{ civilStatus_data }}</div>
          </div>
          <div class="col-lg-6 mt-3" *ngIf="dniSpouseFlag">
            <div class="data-confirm__label">
              Número de documento de tu cónyuge
            </div>
            <div
              class="data-confirm__value"
              data-value="datospersona_numerodocumentoconyuge"
            >
              {{ dniSpouseData }}
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Ocupación actual</div>
            <div class="data-confirm__value">{{ employment }}</div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Ingreso mensual líquido</div>
            <div class="data-confirm__value">{{ monthlyEarning }}</div>
          </div>
        </div>
      </div>

      <div
        class="section-step__subtitle mt-5 animate"
        style="transition-delay: 0.35s"
      >
        Direccion y método de envío
      </div>

      <div class="data-confirm animate" style="transition-delay: 0.4s">
        <div class="row">
          <div class="col-lg-6">
            <div class="data-confirm__label">Departamento</div>
            <div class="data-confirm__value">{{ departament }}</div>
          </div>
          <div class="col-lg-6 mt-3 mt-lg-0">
            <div class="data-confirm__label">Localidad</div>
            <div class="data-confirm__value">{{ locality }}</div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="data-confirm__label">Dirección</div>
            <div class="data-confirm__value">{{ direction }}</div>
          </div>
          <div class="col-lg-6 mt-3" *ngIf="!retirementFlag">
            <div class="data-confirm__label">Recibe tu tarjeta en:</div>
            <div class="data-confirm__value" data-value="entregaretira">
              {{ recibeCard }}
            </div>
          </div>
          <div class="col-lg-6 mt-3" *ngIf="retirementFlag">
            <div class="data-confirm__label">Recibe tu tarjeta en:</div>
            <div class="data-confirm__value" data-value="entregaretira">
              {{ retirementDepartament }}, {{ retirementLocality }},
              {{ retirementDirection }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xl-5 position-static">
      <div class="captcha">
          <ngx-invisible-recaptcha #captchaElem
            [siteKey]="siteKey"
            (reset)="handleReset()"
            (ready)="handleReady()"
            (success)="handleSuccess($event)"
            (expire)="handleExpire()"
            (error)="handleError()"
            [useGlobalDomain]="false"
            [theme]="theme"
            [type]="type"
            [badge]="badge"
            [ngModel]="recaptcha"
            [ngModelOptions]="{ standalone: true }">
          </ngx-invisible-recaptcha>
      </div>
      <div class="section-step__buttons">
        <app-back-button-arrow></app-back-button-arrow>
        <app-form-button
          (eventToSend)="sendRequestData()"
          [btnDisabled]="!finishBtnFlag"
        ></app-form-button>
      </div>
    </div>
  </div>
</div>
