<!-- Listado sucursales -->
<div class="sucursales animate" style="transition-delay: 0.45s">
  <!-- Montevideo -->
  <div
    *ngIf="officePlace"
    class="sucursales_montevideo sucursales__area active"
  >
    <!-- add class 'active' -->
    <div class="section-step__subtitle mb-3">Montevideo</div>
    <ul class="sucursales__list">
      <li class="sucursal">
        <span class="sucursal__name">Casa Central</span>
        <span class="sucursal__address">Colonia 1426</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Paraguay</span>
        <span class="sucursal__address">18 de Julio 1100</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Colón</span>
        <span class="sucursal__address">Garzón 1978</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Paso Molino</span>
        <span class="sucursal__address">Agraciada 4182</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Shopping Montevideo</span>
        <span class="sucursal__address"
          >Luis Alberto de Herrera 1320 Local 502</span
        >
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Shopping Portones</span>
        <span class="sucursal__address"
          >Av. Italia 5775 Local 2 Primer Nivel</span
        >
      </li>     
      <li class="sucursal">
        <span class="sucursal__name">Shopping Nuevocentro</span>
        <span class="sucursal__address"
          >Avda. Luis Alberto de Herrera y Bvar. Artigas, Loc. 143</span
        >
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Shopping Tres Cruces</span>
        <span class="sucursal__address"
          >Bv. Artigas 1825 Locales 44, 45 y 46</span
        >
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Unión</span>
        <span class="sucursal__address">8 de Octubre 3620</span>
      </li>
    </ul>
  </div>

  <!-- Interior -->
  <div *ngIf="!officePlace" class="sucursales_interior sucursales__area active">
    <!-- add class 'active' -->
    <div class="section-step__subtitle mb-3">Interior</div>
    <ul class="sucursales__list">
      <li class="sucursal">
        <span class="sucursal__name">Canelones</span>
        <span class="sucursal__address">Treinta y Tres 495 esq. Artigas</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Colonia</span>
        <span class="sucursal__address">General Flores 478</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Durazno</span>
        <span class="sucursal__address">18 de julio 562</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Florida</span>
        <span class="sucursal__address">Antonio María Fernández 668</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Las Piedras</span>
        <span class="sucursal__address">Avda. Artigas 650</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Maldonado</span>
        <span class="sucursal__address">18 de Julio y Florida</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Melo</span>
        <span class="sucursal__address">Florencio Sánchez 741 Bis</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Mercedes</span>
        <span class="sucursal__address">Wilson Ferreira Aldunate 738</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Minas</span>
        <span class="sucursal__address">25 de mayo 572</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Pando</span>
        <span class="sucursal__address">Dr. Cesar Piovene 1031</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Paysandú</span>
        <span class="sucursal__address">18 de Julio 1187</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Rivera</span>
        <span class="sucursal__address">Sarandí 736 esq. Av. Brasil</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Rocha</span>
        <span class="sucursal__address">25 de Mayo esquina Aquiles Sención</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Salto</span>
        <span class="sucursal__address">Av. Uruguay 878</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">San José</span>
        <span class="sucursal__address">Peatonal Asamblea 607</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Shopping Costa Urbana</span>
        <span class="sucursal__address">Av. Giannattasio Km: 21 local 131-b</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Tacuarembó</span>
        <span class="sucursal__address">25 de mayo 281</span>
      </li>
      <li class="sucursal">
        <span class="sucursal__name">Treinta y Tres</span>
        <span class="sucursal__address">Basilio Araujo 287</span>
      </li>
    </ul>
  </div>
</div>